.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.opacity-0 {
  opacity: 0;
}

.z-max {
  z-index: 999999999999;
}

.border-none {
  border: none !important;
}

.outline-none {
  outline: none !important;
}

.btn-naked {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.text-file-helper {
  color: #bcbcbc;
  font-size: 14px;
  margin-bottom: 20px;
}

.upload-container {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  background: #edf1f3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 20px;
}

.upload-container p {
  font-size: 10px;
  color: #051336;
  margin-top: 3px;
}

.btn-primary {
  background-color: #253950;
  border-color: #253950;
}

.btn-primary:hover {
  background-color: #1b2c40;
  border-color: #1b2c40;
}

.btn-light {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.btn-light:hover {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.font-weight-bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.inline-table {
  display: inline-table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.place-content-end {
  place-content: end;
}

.place-content-between {
  place-content: space-between;
}

.place-content-around {
  place-content: space-around;
}

.place-content-evenly {
  place-content: space-evenly;
}

.place-content-stretch {
  place-content: stretch;
}

.place-items-start {
  place-items: start;
}

.place-items-end {
  place-items: end;
}

.place-items-center {
  place-items: center;
}

.place-items-stretch {
  place-items: stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.no-wrap {
  white-space: nowrap;
}

.modal-content {
  border-radius: 10px !important;
  border: none !important;
}

.emoji-mart {
  width: auto !important;
  z-index: 999999;
  right: 0px;
}

@media screen and (max-width: 1406px) {
  .hideBelow1406 {
    display: none !important;
  }
}
